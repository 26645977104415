<template>
  <section>
    <b-card no-body id="surgery-product-card" class="mb-2">
      <b-card-header class="header pt-3 pb-1" role="tab">
        <h5>Mat/med vinculados ao procedimento</h5>
        <b-button v-b-toggle.linked-products-accordion variant="link">
          Ver menos
        </b-button>
      </b-card-header>
      <b-collapse id="linked-products-accordion" visible accordion="linked-products-accordion" role="tabpanel">
        <b-card-body class="card-body pt-0">
          <hr class="mt-0"/>
          <b-container v-for="(procedure,index) in surgery?.procedures" :key="index" class="m-0 p-0 procedures">
            <b-row class="procedure-container">
              <ItemIconHandler :type="procedure?.clinic_procedure_by_item?.type" class="mr-2"/>
              <span>
                {{ parseProcedureType(procedure?.clinic_procedure_by_item?.type) }} |
              </span>
              <p class="mb-0 ml-1">
                {{ procedure?.item?.name || procedure?.item?.procedure?.name || procedure?.clinic_procedure_by_item?.name }}
              </p>
            </b-row>
            <SearchProductTemplates @select="item => addSurgeryItem(item, procedure.id)"/>
            <div v-for="loading in loadingCount" :key="loading" class="d-flex justify-content-center text-align-center w-100">
              <b-skeleton type="table" class="w-100 mt-1"/>
            </div>
            <section class="table-container" v-if="Object.keys(surgeryProductTemplates)?.length">
              <SurgeryProductTemplatesTable
                :surgeryProductTemplates="surgeryProductTemplates[procedure?.id]"
                @removeByKitTemplateId="id => removeByKitTemplateId(id, procedure?.id)"
                @removeSurgeryProductTemplate="id => removeSurgeryProductTemplate(id, procedure?.id)"
              />
            </section>
            <div v-if="!Object.keys(surgeryProductTemplates)?.length && !loadingCount" class="d-flex justify-content-center text-align-center w-100">
              <p>Nenhum produto ou medicamento vinculado ao procedimento</p>
            </div>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
  </section>
</template>

<script>
import { parseProcedureType } from '@/utils/procedure-helper.js'
export default {
  name: 'SurgeryProductsTemplates',
  components: {
    ItemIconHandler: () => import('@/components/Overview/ItemIconHandler.vue'),
    SearchProductTemplates: () => import('@/components/SurgeryCenter/Products/Templates/SearchProductTemplates.vue'),
    SurgeryProductTemplatesTable: () => import('@/components/SurgeryCenter/Products/Templates/SurgeryProductTemplatesTable.vue'),
  },
  props: {
    surgery: {type: Object, required: true},
    loading: Boolean,
  },
  data: () => ({
    surgeryProductTemplates: [],
    loadingCount: 0,
  }),
  created() {
    this.getSurgeryProductsTemplates()
  },
  methods: {
    parseProcedureType,
    async getSurgeryProductsTemplates() {
      try {
        this.loadingCount = 4
        this.surgeryProductTemplates = []
        const { data } = await this.api.getSurgeryProductsTemplates(this.surgery.id)

        data.map(template => {
          if (!this.surgeryProductTemplates[template?.surgery_procedure_id])
            this.$set(this.surgeryProductTemplates, template.surgery_procedure_id, [])

          this.surgeryProductTemplates[template.surgery_procedure_id].push(template)
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loadingCount = 0
      }
    },
    async addKitTemplateToSurgeryProductTemplate(kitTemplate, surgeryProcedureId) {
      try {
        const payload = kitTemplate?.kit_template_products
          .map(kitTemplateProduct => ({
            ...kitTemplateProduct,
            surgery_procedure_id: surgeryProcedureId,
          })
        )
        this.loadingCount = payload?.length
        await this.api.storeManySurgeryProductsTemplates(payload)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loadingCount = 0
      }
    },
    async createSurgeryProductTemplate(product, surgeryProcedureId) {
      try {
        this.loadingCount = 1
        const payload = {
          surgery_procedure_id: surgeryProcedureId,
          product_id: product.id,
          quantity: 1,
        }
        await this.api.storeSurgeryProductsTemplates(payload)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loadingCount = 0
      }
    },
    async addSurgeryItem(item, surgeryProcedureId) {

      if (item?.value?.kit_template_products )
        await this.addKitTemplateToSurgeryProductTemplate(item.value, surgeryProcedureId)
      else
        await this.createSurgeryProductTemplate(item.value, surgeryProcedureId)

      await this.getSurgeryProductsTemplates()
    },
    async removeSurgeryProductTemplate(id, surgeryProcedureId) {
      const deleted = this.surgeryProductTemplates[surgeryProcedureId]
      try {
        this.$set(this.surgeryProductTemplates, surgeryProcedureId, deleted.filter(el => el?.id !== id))
        await this.api.deleteSurgeryProductsTemplate(id)
      } catch (error) {
        this.$toast.error(error.message)
        this.$set(this.surgeryProductTemplates, surgeryProcedureId, deleted)
      } finally {
        this.loadingCount = 0
      }
    },
    async removeByKitTemplateId(kitTemplateId, surgeryProcedureId) {
      const deleted = this.surgeryProductTemplates[surgeryProcedureId]
      try {
        this.$set(this.surgeryProductTemplates, surgeryProcedureId, deleted.filter(el => el?.kit_template_id !== kitTemplateId))
        await this.api.deleteSurgeryProductsTemplatesByKitTemplateId(kitTemplateId)
      } catch (error) {
        this.$toast.error(error.message)
        this.$set(this.surgeryProductTemplates, surgeryProcedureId, deleted)
      } finally {
        this.loadingCount = 0
      }
    },
  },
}
</script>

<style lang="scss" src="./SurgeryItems.scss" />
